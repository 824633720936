<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="보호구 신청 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="saveType"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-datepicker
                :required="true"
                label='신청일'
                :editable="editable"
                name="requestDate"
                v-model="data.requestDate"
              />
            </div>
            <div class="col-3">
              <c-plant
                type="edit"
                :required="true"
                label='사업장'
                :editable="editable"
                name="plantCd"
                v-model="data.plantCd"
              />
            </div>
            <div class="col-3">
              <c-dept
                :required="true"
                label='신청부서'
                :editable="editable"
                name="requestDeptCd"
                v-model="data.requestDeptCd"
              />
            </div>
            <div class="col-3">
              <c-field
                :required="true"
                label='신청자'
                :editable="editable"
                name="requestUserId"
                v-model="data.requestUserId"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="보호구 신청 목록"
        tableId="table"
        :columns="grid.columns"
        :data="data.detailList"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="editable"
        gridHeight="600px"
        selection="multiple"
        rowKey="requestListId"
      >
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addGear" />
              <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="removeGear" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-give-request-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        requestGiveInfoId: '',
        stepCd: '',
        plantCd: '',
        giveTypeCd: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        requestDate: '',
        requestDeptCd: '',
        requestUserId: '',
        giveDt: '',
        giveTypeCd: '',
        giveDeptCd: '',
        giveUserId: '',
        sysApprovalRequestId: '',

        detailList: [],
        deleteDetailList: [],
      },
      grid: {
        columns: [
          {
            name: 'safetyTypeName',
            field: 'safetyTypeName',
            label: '보호구종류',
            style: 'width:120px',
            align: 'center',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구명',
            style: 'width:200px',
            align: 'left',
            setHeader: true,
            sortable: false,
          },
          {
            required: true,
            name: 'requestCount',
            field: 'requestCount',
            label: '신청수량',
            style: 'width:80px',
            align: 'right',
            type: 'number',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            style: 'width:350px',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      editable: true,
      isApproval: false,
      disable: false,
      saveUrl: 'transactionConfig.sop.sas.request.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
    };
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.statusCd === 'SSG0000001', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000012', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          requestGiveInfoId: this.data.requestGiveInfoId,
          isApprContent: true
        },
        approvalRequestName: '보호구 신청 ['+this.data.requestDate+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.requestGiveInfoId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    disabled() {
      return this.popupParam.stepCd === 'SSG0000005' || this.popupParam.stepCd === 'SSG0000010' || this.popupParam.stepCd === 'SSG0000015'
    },
    isRemove() {
      return this.popupParam.requestGiveInfoId && Boolean(!this.data.sysApprovalRequestId)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.sas.request.get.url;
      this.insertUrl = transactionConfig.sop.sas.request.insert.url;
      this.updateUrl = transactionConfig.sop.sas.request.update.url;
      this.deleteUrl = transactionConfig.sop.sas.request.delete.url;

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.requestGiveInfoId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.requestGiveInfoId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data
        },);
      } 
    },
    saveData() {
      if (this.data.requestGiveInfoId) {
        this.data.chgUserId = this.$store.getters.user.userId
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.data.regUserId = this.$store.getters.user.userId
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.data.detailList)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.requestGiveInfoId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다. 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.requestGiveInfoId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addGear() {
      this.popupOptions.title = '보호구 검색'; // 보호구 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/sas/safetyGearPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeGearPopup;
    },
    closeGearPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.detailList, { safetyGearId: item.safetyGearId }) === -1) {
            this.data.detailList.push({
              requestListId: uid(),
              requestGiveInfoId: this.popupParam.requestGiveInfoId,
              safetyGearId: item.safetyGearId,
              safetyTypeName: item.safetyTypeName,
              safetyGearName: item.safetyGearName,
              requestCount: '',
              remarks: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeGear() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.data.deleteDetailList) {
          this.data.deleteDetailList = []
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.data.deleteDetailList, { requestListId: item.requestListId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteDetailList.push(item)
          }
          this.data.detailList = this.$_.reject(this.data.detailList, item)
        });
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
  },
};
</script>
